import {
  BackgroundShapes,
  ButtonShapes,
  HeaderTypes,
  LogoTypes,
} from '../Appearance/types';
import {Fonts, GoogleFonts} from '../Common/types';

import {BioBackgroundStyles} from '../Bio/types';
import {baseBackgroundImages} from '../Appearance/components/BackgroundImageSelector';

export interface ThemeV2 {
  _hasUserEditedBackground: boolean;
  _hasUserEditedColor: boolean;
  logoType: LogoTypes;
  profilePhoto: string;
  logoPhoto: string;
  name: string;
  headerType: HeaderTypes;
  title: string;
  subtext: string;
  backgroundImage: string;
  userBackgroundImages: string[];
  backgroundBlockShape: BackgroundShapes;
  backgroundBlockColor: string;
  backgroundBlockTextColor: string;
  buttonShape: ButtonShapes;
  buttonColor: string;
  buttonFontColor: string;
  bodyFont: GoogleFonts;
  headlineFont: GoogleFonts;
  creatorProfilePhoto: string;
}

export interface BioTheme {
  font: GoogleFonts;
  fontColor: string;
  buttonColor: string;
  buttonShape: ButtonShapes;
  buttonFontColor: string;
  backgroundStyle: BioBackgroundStyles;
  backgroundColor: string;
  backgroundImage: string;
  overlayColor: string;
  overlayOpacity: number;
  bioName: string;
  bioSubText: string;
}

export interface ThemeBase {
  store: {
    isDefault?: boolean;
    social?: Record<string, string>;
    nav: {
      home: string;
      about: string;
    };
    discountText?: string;
    subtitleText?: string;
    productDescriptionHeadline?: string;
  };
}

export interface Theme extends Record<string, unknown> {
  v2?: ThemeV2;
  bio?: BioTheme;
  _version?: number;
  store: {
    isDefault?: boolean;
    name: string;
    logo: string;
    profile?: string;
    hero?: string;
    heroMobile?: string;
    heroClassName?: string;
    favicon?: string;
    social?: Record<string, string>;
    nav: {
      home: string;
      about: string;
    };
    discountText?: string;
    subtitleText?: string;
    productDescriptionHeadline?: string;
  };
  palette: {
    primary: string;
    white?: string;
    black?: string;
    secondary?: string;
  };
  font: {
    headline: Fonts;
    body: Fonts;
  };
}

export const themes: Record<string, Theme> = {
  artica: {
    store: {
      isDefault: true,
      name: '',
      logo: '',
      favicon: '',
      nav: {
        home: '',
        about: '',
      },
    },
    palette: {
      white: '#FFFFFF',
      black: '#000000',
      primary: '#171717',
      secondary: '#D2F6FD',
    },
    font: {
      headline: 'Inter',
      body: 'Open Sans',
    },
  },
};

export const ThemeBaseV2 = {
  store: {
    isDefault: true,
    nav: {
      home: '',
      about: '',
    },
  },
};

export const getDefaultThemeV2 = (expertName: string) => ({
  _hasUserEditedColor: false,
  logoType: LogoTypes.profile,
  profilePhoto: '',
  logoPhoto: '',
  name: expertName,
  headerType: HeaderTypes.header,
  title: expertName,
  subtext: 'Shop my personal top picks - exclusive discounts already applied!',
  backgroundImage: baseBackgroundImages[0].src,
  userBackgroundImages: [],
  backgroundBlockShape: BackgroundShapes.wave,
  backgroundBlockColor: '#111111',
  backgroundBlockTextColor: '#ffffff',
  buttonShape: ButtonShapes.rounded,
  buttonColor: '#000000',
  buttonFontColor: '#ffffff',
  bodyFont: GoogleFonts.Inter,
  headlineFont: GoogleFonts.Inter,
});

export const defaulBioTheme = {
  font: GoogleFonts.Inter,
  fontColor: '#000000',
  buttonColor: '#ffffff',
  buttonShape: ButtonShapes.rounded,
  buttonFontColor: '#000000',
  backgroundStyle: BioBackgroundStyles.Light,
  backgroundColor: '#000',
  backgroundImage: '',
  overlayColor: '#fff',
  overlayOpacity: 0.9,
  bioName: '',
  bioSubText: '',
};
